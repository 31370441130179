<template>
  <div>
    <!-- The file input for influencers list -->
    <input
      type="file"
      name="upload-influencers-file"
      accept=".csv, .xlsx, .xls"
      class="d-none"
      ref="fileUpload"
      @change="handleFileChange"
    />

    <!-- Show the create form -->
    <v-dialog
      :value="value"
      @input="$emit('input', $event)"
      max-width="600"
      :persistent="isMakingRequest"
    >
      <v-card :loading="isMakingRequest">
        <v-card-title>
          <div class="d-flex justify-space-between align-center width-100">
            <!-- Show the text -->
            <div>Profile Overview</div>

            <div class="d-flex align-center">
              <!-- Show the button to download example sheet -->
              <v-btn
                text
                depressed
                class="mr-3"
                color="primary"
                :disabled="shouldFormBeDisabled"
                @click="downloadSampleSheet"
              >
                <v-icon left> download </v-icon>

                Sample
              </v-btn>

              <!-- Show the button to upload in bulk -->
              <v-btn
                depressed
                color="primary"
                :disabled="shouldFormBeDisabled"
                :loading="isUploading"
                @click="$refs.fileUpload && $refs.fileUpload.click()"
              >
                <v-icon left> file_upload </v-icon>

                Upload
              </v-btn>
            </div>
          </div>
        </v-card-title>

        <v-card-text class="pt-6">
          <div class="d-flex mb-6">
            <!-- show the input for searching terms -->
            <profile-selector
              @change="handleProfileChange"
              :platform="selectedPlatform"
              :use-combobox="true"
              :hide-no-data="true"
              type="search"
              label="Search Profile"
              class="rounded-tr-0 rounded-br-0"
              outlined
            />

            <!-- the select options for different platforms -->
            <platform-selector
              :value="selectedPlatform"
              @input="handlePlatformChange"
              :show-youtube="false"
              class="rounded-tl-0 rounded-bl-0"
              outlined
            />
          </div>

          <date-range-selector
            :max="dayjs().format('YYYY-MM-DD')"
            :min="dayjs().subtract(3, 'month').format('YYYY-MM-DD')"
            :value="dateRangeValue"
            :default-date-range="1"
            :max-date-range="93"
            @input="handleDateRangeValue"
          />
        </v-card-text>

        <v-card-actions>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip
                label
                color="primary"
                v-on="on"
              >
                <v-icon
                  left
                  small
                >
                  account_balance
                </v-icon>

                {{ nFormatter(availableModuleUsage) }} Reports
              </v-chip>
            </template>

            <span>
              You have {{ availableModuleUsage }} reports available to generate
            </span>
          </v-tooltip> -->

          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="$emit('input', false)"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest"
            @click="handleSubmit"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import node package
const CSV = require("csv")
const XLSX = require("xlsx")

// Import helper functions
import { required } from "vuelidate/lib/validators"
import csvToJson from "@/helpers/csvToJson"
import sheetToJson from "@/helpers/sheetToJson"
import platformRegex from "@/helpers/platformRegex"

// Import children components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip')
const ProfileSelector = () => import(/* webpackChunkName: "profile-selector" */ "@/blocks/common/selectors/ProfileSelector.vue")
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector.vue")
const DateRangeSelector = () => import(/* webpackChunkName: "date-range-selector" */ "@/blocks/common/form/DateRangeSelector.vue")

// Define function for initial form data
const initialFormData = () => ({
  startDate: null,
  endDate: null
})

// Export the SFC
export default {
  // Name of the component
  name: "ProfileOverviewForm",

  // Register the components
  components: {
    ProfileChip,
    ProfileSelector,
    PlatformSelector,
    DateRangeSelector
  },

  // Define the props
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  // Define local data variables
  data: () => ({
    isUploading: false,
    isMakingRequest: false,

    formData: initialFormData(),
    dateRangeValue: null
  }),

  // Define local computable properties
  computed: {
    /**
     * If the code is making a network request, disable the buttons and inputs
     *
     * @returns {Boolean}
     */
    shouldFormBeDisabled() {
      return this.isMakingRequest || this.isUploading
    },

    /**
     * Compute the available module usage
     *
     * @returns {Number}
     */
    availableModuleUsage() {
      return this.$store.getters["auth/availableModuleUsage"]("profile-overview")
    },

    /**
     * Get the selected platform
     */
    selectedPlatform: {
      get() {
        return this.$store.getters["profileOverview/selectedPlatform"]
      },

      set(value) {
        this.$store.dispatch("profileOverview/updateSelectedPlatform", value)
      }
    },

    /**
     * Get the selected influencer
     */
    selectedInfluencer: {
      get() {
        return this.$store.getters["profileOverview/selectedInfluencer"]
      },

      set(value) {
        this.$store.dispatch("profileOverview/updateSelectedInfluencer", value)
      }
    },
  },

  // Define the validations
  validations() {
    return {
      formData: {
        startDate: {
          required,
          minimum: (v) => dayjs().diff(dayjs(v), 'day') <= dayjs().diff(dayjs().subtract(3, "month").format("YYYY-MM-DD"), 'day')
        },
        endDate: {
          required,
          minimum: (v) => dayjs(v).diff(this.formData.startDate, 'day') >= 1
        },
      }
    }
  },

  // Define local method functions
  methods: {
    /**
     * Copy the value from child to current state
     *
     * @param {null|Array} value
     */
    async handleDateRangeValue(value) {
      // Copy the value to local value
      this.dateRangeValue = value

      // Check if it's an array
      if (value !== null) {
        // Also update the filter values
        this.formData.startDate = value[0] || null
        this.formData.endDate = value[1] || null

        // Validate the inputs
        await this.$v.formData.startDate.$touch()
        await this.$v.formData.endDate.$touch()

        // If it is invalid
        if (this.$v.formData.startDate.$anyError || this.$v.formData.endDate.$anyError) {
          // Show a snackbar
          this.$store.dispatch("toasts/add", {
            text: "Please enter a valid date range",
          })
        }
      }
    },

    /**
     * Handle the profile change
     *
     * @param {Object} profile
     */
    handleProfileChange(profile) {
      this.selectedInfluencer = profile
    },

    /**
     * Handle the platform change
     *
     * @param {String} platform
     */
    handlePlatformChange(platform) {
      this.selectedPlatform = platform
    },

    /**
     * Handle the form submission
     *
     * @return {void}
     */
    handleSubmit() {
      // Check if there's no influencer
      if (!this.selectedInfluencer) {
        // Show an error toast
        this.$store.dispatch("toasts/add", { text: "Please select an influencer" })

        // Return early
        return
      }

      // Call the function
      this.makeCreateRequest([ this.selectedInfluencer.username || this.selectedInfluencer])
    },

    /**
     * Upload influencers in bulk
     *
     * @param {Object} influencers
     * @returns {void}
     */
    async makeCreateRequest(usernames) {
      // If we're already making a request
      if (this.isMakingRequest) {
        return
      }

      // Validate the form
      await this.$v.$touch()

      // Check if the form is invalid
      if (this.$v.$invalid) {
        // Show an error toast
        this.$store.dispatch("toasts/add", { text: "Please fix the errors in the form" })

        // Return early
        return
      }

      // Otherwise, set a global loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Make the network request
      try {
        // Get the response
        await axios({
          url: "/api/profile-overviews",
          method: "POST",
          data: {
            platform: this.selectedPlatform,
            usernames: usernames,

            start_date: this.formData.startDate,
            end_date: this.formData.endDate
          }
        })

        // Refresh the user's balance
        fetchProfile()

        // Show a success toast
        this.$store.dispatch("toasts/add", { text: `${usernames.length > 1 ? 'Reports' : 'Report'} queued! We'll notify you once it's ready.` })

        // Emit the close event
        this.$emit("input", false)
        this.$emit("created")

        // Reset the form data
        this.formData = initialFormData()
        this.$v.$reset()
      } catch (error) {
        // Log the error
        logger({ type: "ProfileOverview/Form/Create Error", error })

        // Show an error toast
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      } finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
      }
    },

    /**
     * Generate and download a sample XLSX sheet file
     *
     * @returns {void}
     */
    downloadSampleSheet() {
      // To disable the buttons
      this.isMakingRequest = true

      // Define the rows
      const rows = [
        ["Profile URL/username"]
      ]

      // Define the platform map
      const platformMap = {
        "instagram": "https://www.instagram.com/",
        "youtube": "https://www.youtube.com/@",
        "tiktok": "https://www.tiktok.com/@"
      }

      // Add the platform rows
      for (let i = 1; i <= 3; i++) {
        rows.push([
          `${platformMap[this.selectedPlatform]}username${i}`
        ])
      }

      // Initiate a workbook instance
      const workbook = XLSX.utils.book_new()

      // Create the worksheet
      const worksheet = XLSX.utils.json_to_sheet([])

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet)

      // Add headers
      XLSX.utils.sheet_add_aoa(worksheet, rows)

      // Style with width
      worksheet["!cols"] = Array(10).fill({ wch: 16 })

      // Download this instance
      XLSX.writeFile(workbook, `Profile Overview Sample Sheet - ${this.host.name}.xlsx`)

      // Enable the buttons again
      this.isMakingRequest = false
    },

    /**
     * Handle the file input change for bulk influencers upload
     *
     * @param {Object} event
     * @returns {void}
     */
    handleFileChange(event) {
      // Check if the event has any files in it
      if (!event.target.files || !event.target.files.length) {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "No file selected" })

        // End the execution
        return
      }

      // Get the file object
      const file = event.target.files[0]

      // Validate the mime type of this file is CSV
      if (
        !["text/csv", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(file.type)
      ) {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "File is not a CSV or XLSX" })

        // End the execution
        return
      }

      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isUploading = true

      // Define a function to hide the loaders
      const doHideLoaders = () => {
        this.$store.dispatch("loaders/remove", loaderId)
        this.isUploading = false
      }

      // Define a function to extract URLs and make network request
      const postProcessor = async (result) => {
        // Get all the URLs from the CSV file
        const URLs = []

        // Keep a count of URLs
        let counter = 0

        // Get the rows
        const rows = Array.isArray(result) ? result : []

        // Go through each row
        for (const row of rows) {
          // If the row is an array
          const headers = Array.isArray(row) ? row : Object.keys(row || {})
          const columns = Array.isArray(row) ? row : Object.values(row || {})

          // Go through each column
          for (const column of columns) {
            // If the column is empty
            if (!column) {
              continue
            }

            // Get the value
            const value = column.trim()

            // Check if the value is valid
            const match = value.match(platformRegex[this.selectedPlatform])

            // Push the value
            URLs.push(match && match[1] ? match[1] : value)

            // Increment the counter
            counter++
          }
        }

        // If there are no profile links
        if (counter === 0) {
          // Show an error message
          this.$store.dispatch("toasts/add", { text: "No valid URL found in this CSV file" })

          // Also hide the loaders
          doHideLoaders()

          // End the execution
          return
        }
        // Otherwise, if there are more than 50 URLs
        else if (counter > 50) {
          // Show an error message
          this.$store.dispatch("toasts/add", { text: "You cannot add more than 50 profiles at once" })

          // Also hide the loaders
          doHideLoaders()

          // End the execution
          return
        }
        // Finally
        else {
          // Make network request
          await this.makeCreateRequest(URLs)
          // Hide the loaders
          doHideLoaders()
        }
      }

      // Otherwise, try to read the file
      const reader = new FileReader()

      // Add a callback function on it
      reader.addEventListener("load", async () => {
        // If the file type is CSV
        if (file.type === "text/csv") {
          // Try parsing the text as a CSV document
          CSV.parse(reader.result, async (error, result) => {
            // If there's any error
            if (error) {
              // Log the error
              logger({ type: "CSV Parse Error", error })

              // Show an error message
              this.$store.dispatch("toasts/add", { text: "File is not a valid CSV document" })

              // Also hide the loaders
              doHideLoaders()

              // End the execution
              return
            }
            // Otherwise
            else {
              const items = csvToJson(result)

              // Use the helper function
              postProcessor(items)
            }
          })
        }
        // Otherwise
        else {
          const buffer = reader.result

          // Load the file as XLSX
          const workbook = XLSX.read(buffer)
          // Translate the XLSX into a JSON object
          const items = sheetToJson(workbook.Sheets[workbook.SheetNames[0]])

          // Use the helper function
          postProcessor(items)
        }
      })

      // If the file is of type CSV
      if (file.type === "text/csv") {
        // Read the file as a text
        reader.readAsText(file)
      }
      // Otherwise
      else {
        // Read the file as array buffer
        reader.readAsArrayBuffer(file)
      }
    },
  }
}
</script>
